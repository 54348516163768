import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1185px;
  padding: 0 15px; 
`;

const ContainerSm = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
);

ContainerSm.defaultProps = {
  leftPadding: false,
};

ContainerSm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ContainerSm;


