import React from 'react';
import PropTypes from 'prop-types';
// Components
import FaqItem from './components/FaqItem';
// Style
import {
  FaqContainer,
} from './styled';

const Faq = ({ data }) => {
  return (
    <FaqContainer>
      {data.map((item) => {
        const { question, answer, id } = item;
        return (
          <FaqItem key={id} data={{ question, answer }} />
        );
      })}
    </FaqContainer>
  );
};

Faq.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Faq;
