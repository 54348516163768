import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// Layout
import MainLayout from '../components/layouts/MainLayout';
// Components
import Seo from "../components/templates/Seo/seo";
import FaqSection from '../components/templates/FaqSection';

const FaqPage = () => {
  const { seo, ...data } = FaqQuery();

  const SEOSchema = {"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://torontocannabisauthority.ca/#website","url":"https://torontocannabisauthority.ca/","name":"TORONTO CANNABIS AUTHORITY","description":"We\u2019ve Got The Best Weed in Toronto!","potentialAction":[{"@type":"SearchAction","target":"https://torontocannabisauthority.ca/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://torontocannabisauthority.ca/#webpage","url":"https://torontocannabisauthority.ca/","name":"We\u2019ve Got The Best Weed in Toronto! - TORONTO CANNABIS AUTHORITY","isPartOf":{"@id":"https://torontocannabisauthority.ca/#website"},"datePublished":"2021-05-14T09:02:29+00:00","dateModified":"2021-05-14T10:28:42+00:00","breadcrumb":{"@id":"https://torontocannabisauthority.ca/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://torontocannabisauthority.ca/"]}]},{"@type":"BreadcrumbList","@id":"https://torontocannabisauthority.ca/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"item":{"@id":"https://torontocannabisauthority.ca/#webpage"}}]}]}


  return (
    <MainLayout>
        <Seo
          title={seo.metaTitle}
          description={seo.metaDescription}
          keywords={seo.keywords}
          schemaMarkup={SEOSchema}
        />
      <FaqSection data={data} />
    </MainLayout>
  );
};

export default FaqPage;

const FaqQuery = () => {
  const data = useStaticQuery(
    graphql`
      query FaqQuery {
        strapiFaq {
            seo {
                metaDescription
                metaTitle
                keywords
            }
          title
          text
          faq {
            answer
            question
            id
          }
        }
      }
    `
  );

  const {
    strapiFaq: faq
  } = data;

  return faq;
}
