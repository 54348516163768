import ReactMarkdown from 'react-markdown';
// UI
import H1 from '../../UI/Titles/H1';
// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
import Typography from '../../../assets/styles/Typography';
import { tablet } from "../../../assets/styles/Responsive";

const Wrapper = styled.div`
  padding: 175px 0 80px;
  background-color: ${Colors.gin};

  @media (max-width: ${tablet}) {
    padding: 120px 0 85px;
  }
`;

const Head1 = styled(H1)`
  max-width: 855px;
  margin: 0 auto;
  text-align: center;
  color: ${Colors.green};

  @media (max-width: ${tablet}) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const Text = styled(ReactMarkdown)`
  ${Typography.richText};
  margin: 60px auto 0;
  max-width: 680px;
  text-align: center;

  @media (max-width: ${tablet}) {
    margin-top: 40px;
  }
`;

const FaqWrap = styled.div`
  margin-top: 60px;

  @media (max-width: ${tablet}) {
    margin-top: 80px;
  }
`;

export {
  Wrapper,
  Head1,
  Text,
  FaqWrap,
}