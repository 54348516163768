import ReactMarkdown from 'react-markdown';
// UI
import H4 from '../../../../UI/Titles/H4';
// Styled
import styled from 'styled-components';
import Colors from '../../../../../assets/styles/Colors';
import Typography from '../../../../../assets/styles/Typography';
import Fonts from '../../../../../assets/styles/Fonts';
import { tablet } from "../../../../../assets/styles/Responsive";
// Assets
import Arrow from '../../../../../images/icons/arrow-top-thin.svg';

const FaqWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  border-bottom: 1px solid ${Colors.silver};

  @media (max-width: ${tablet}) {
    padding: 20px 0;
  }
`;

const FaqContent = styled.div`
  width: 100%
`;

const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center
`;

const Head4 = styled(H4)`
  text-transform: none;
  max-width: 750px;
  color: ${Colors.green};
  font-family: ${Fonts.IBMSemiBold};
  line-height: 31.2px;
  user-select: none;
  cursor: pointer;

  @media (max-width: ${tablet}) {
    line-height: 20.8px;
  }
`;

const Answer = styled.div`
  max-height: ${props => props.close ? '0' : `${props.height}px`};
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

const Text = styled(ReactMarkdown)`
  ${Typography.richText};
  margin-top: 0;
  padding-top: 30px;
  max-width: 750px;

  @media (max-width: ${tablet}) {
    margin-top: 0;
    padding-top: 20px;
  }
`;

const ArrowWrap = styled.div`
  padding-left: 25px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
`;

const ArrowIcon = styled(Arrow)`
  position: relative;
  transform: rotate(${props => props.closed ? '180deg' : '0deg'});
  transition: transform 0.3s ease;
`;

export {
  FaqWrap,
  FaqContent,
  Head4,
  Answer,
  Text,
  ArrowWrap,
  ArrowIcon,
  HeadWrapper
}