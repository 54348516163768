import React from 'react';
import PropTypes from 'prop-types';
// Layouts
import ContainerSm from '../../layouts/ContainerSm';
// UI
import Faq from '../../UI/Faq';
import {
  Wrapper,
  Head1,
  Text,
  FaqWrap,
} from './styled';

const FaqSection = ({
  data: {
    title,
    text,
    faq,
  },
}) => {
  return (
    <Wrapper>
      <ContainerSm>
        <Head1 value={title} />
        <Text>{text}</Text>
        <FaqWrap>
          <Faq data={faq} />
        </FaqWrap>
      </ContainerSm>
    </Wrapper>
  );
};
 
FaqSection.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default FaqSection;
