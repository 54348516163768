import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// Style
import {
  FaqWrap,
  FaqContent,
  Head4,
  Answer,
  Text,
  ArrowWrap,
  ArrowIcon,
  HeadWrapper
} from './styled';

const FaqItem = ({ data: { question, answer }}) => {
  const answerRef = useRef(null);
  const [closed, setClosed] = useState(true);
  const [height, setHeight] = useState(0);

  const accordionToggle = useCallback(() => {
    setClosed((c) => {
      setHeight(c ? answerRef.current.scrollHeight : 0);
      return !c;
    });
  }, [setClosed, setHeight, answerRef]);

  return (
    <FaqWrap>
        <FaqContent>
            <HeadWrapper>
                <Head4 onClick={accordionToggle} value={question}/>
                <ArrowWrap onClick={accordionToggle}>
                    <ArrowIcon closed={closed} alt="arrow icon"/>
                </ArrowWrap>
            </HeadWrapper>

            <Answer closed={closed} height={height} ref={answerRef}>
                <Text>{answer}</Text>
            </Answer>
        </FaqContent>
    </FaqWrap>
  );
};

FaqItem.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default FaqItem;
